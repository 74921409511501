import $ from 'jquery';
import Swiper from 'swiper/js/swiper.min';
import uikit from 'uikit';
import noUiSlider from 'nouislider';
import SmoothScroll from 'smooth-scroll';
import 'ace-builds/src-min-noconflict/ace';
import 'ace-builds/src-min-noconflict/mode-dart';
import 'ace-builds/src-min-noconflict/mode-java';
import 'ace-builds/src-min-noconflict/mode-javascript';
import 'ace-builds/src-min-noconflict/mode-objectivec';
import 'ace-builds/src-min-noconflict/mode-csharp';
import 'ace-builds/src-min-noconflict/mode-python';
import 'ace-builds/src-min-noconflict/mode-haxe';
import 'ace-builds/src-min-noconflict/mode-swift';
import artTemplate from 'art-template/lib/template-web';

window.$ = $;
window.Swiper = Swiper;
window.uikit = uikit;
window.noUiSlider = noUiSlider;
window.artTemplate = artTemplate;
window.SmoothScroll = SmoothScroll;
